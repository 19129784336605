import { StationeryTemplateCategoryEnum, useDashboardPreviewStationeryTemplatesQuery } from '@graphql/generated';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { Link } from '@shared/core';
import { Box, BoxProps, ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import React, { useMemo } from 'react';
import { useDashboardPreviewTelemetry } from '../../DashboardPreview.telemetry';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

type UrlMap = Record<StationeryTemplateCategoryEnum.saveTheDate | StationeryTemplateCategoryEnum.invitation | StationeryTemplateCategoryEnum.thankYou, string>;
type RedirectCtaClickHandler = (templateCategory: StationeryTemplateCategoryEnum, isJoyPrintable: boolean) => () => void;
type PrintConfig = Record<
  StationeryTemplateCategoryEnum.invitation | StationeryTemplateCategoryEnum.saveTheDate,
  | {
      id: string;
      thumbnailUrl: string;
      themeId: string;
    }
  | undefined
>;

const PreviewAssetsContainer: React.FC = ({ children }) => {
  return (
    <Flex height={{ _: 250, lg1: 400 }} maxWidth="100%" paddingY={3} justifyContent="center" columnGap={5}>
      {children}
    </Flex>
  );
};

const PreviewAsset = ({ url, alt, ...restProps }: { url: string; alt: string } & BoxProps) => {
  return <Box as="img" alt={alt} height={'100%'} src={url} boxShadow="0px 1.32px 5.28px 0px rgba(0, 0, 0, 0.07), 0px 2.31px 8.9px 0px rgba(44, 41, 37, 0.06);" {...restProps} />;
};

const CtaRedirects = ({ urls, isJoyPrintable, onRedirectCtaClick }: { urls: UrlMap; onRedirectCtaClick: RedirectCtaClickHandler; isJoyPrintable: boolean }) => {
  return (
    <Flex flexDirection="column" rowGap={4} width={207} maxWidth="100%">
      <ButtonV2
        as={Link}
        to={urls.saveTheDate}
        onClick={onRedirectCtaClick(StationeryTemplateCategoryEnum.saveTheDate, isJoyPrintable)}
        variant="outline"
        intent="neutral"
        typographyVariant="button2"
      >
        Explore Save The Dates
      </ButtonV2>
      <ButtonV2
        as={Link}
        to={urls.invitation}
        onClick={onRedirectCtaClick(StationeryTemplateCategoryEnum.invitation, isJoyPrintable)}
        variant="outline"
        intent="neutral"
        typographyVariant="button2"
      >
        Explore Invitations
      </ButtonV2>
    </Flex>
  );
};

const NonMatchingTemplates = ({ urls, onRedirectCtaClick }: { urls: UrlMap; onRedirectCtaClick: RedirectCtaClickHandler }) => {
  return (
    <>
      <TextV2 typographyVariant={['hed4']} textAlign="center">
        Beautiful Paper & Digital Cards to Match Your Style
      </TextV2>
      <PreviewAssetsContainer>
        <PreviewAsset width="100%" objectFit="cover" url="https://withjoy.com/media/print/stationery_preview.png" alt="Explore Joy Print" />
      </PreviewAssetsContainer>
      <CtaRedirects isJoyPrintable={false} urls={urls} onRedirectCtaClick={onRedirectCtaClick} />
    </>
  );
};

const MatchingTemplates = ({ onRedirectCtaClick, printConfig, urls }: { printConfig: PrintConfig; urls: UrlMap; onRedirectCtaClick: RedirectCtaClickHandler }) => {
  return (
    <>
      <TextV2 typographyVariant={['hed4']} textAlign="center">
        Beautiful Paper & Digital Cards to Match Your Website
      </TextV2>
      <PreviewAssetsContainer>
        {printConfig.saveTheDate && <PreviewAsset url={printConfig.saveTheDate.thumbnailUrl} alt="Save the Date" />}
        {printConfig.invitation && <PreviewAsset url={printConfig.invitation.thumbnailUrl} alt="Invitation" />}
      </PreviewAssetsContainer>
      <CtaRedirects isJoyPrintable={true} urls={urls} onRedirectCtaClick={onRedirectCtaClick} />
    </>
  );
};

interface JoyStationeryPreviewProps {
  eventHandle: string;
  themeId: string | undefined;
}
export const JoyStationery = (props: JoyStationeryPreviewProps) => {
  const { eventHandle, themeId } = props;

  const { data, loading } = useDashboardPreviewStationeryTemplatesQuery({
    batchMode: 'fast',
    variables: {
      themeId: themeId!
    },
    skip: !themeId
  });

  const { printStationeryRedirectButtonClicked } = useDashboardPreviewTelemetry();

  const onRedirectCtaClicked: RedirectCtaClickHandler = useEventCallback((templateCategory, isJoyPrintable) => {
    return () => {
      printStationeryRedirectButtonClicked({
        stationeryTemplateCategory: templateCategory,
        themeId,
        isJoyPrintable
      });
    };
  });

  const printConfig = useMemo(() => {
    if (themeId) {
      const config: PrintConfig = {
        saveTheDate: undefined,
        invitation: undefined
      };
      data?.stationeryTemplates?.forEach(template => {
        if (template.category in config && !!template.thumbnailUrl) {
          // Adding a ts-ignore because of the `thumbnailUrl` is typed as possibly undefined despite the check above
          // @ts-ignore
          config[template.category] = template;
        }
      });
      return config;
    }
    return null;
  }, [data, themeId]);

  const urls: UrlMap = useMemo(() => {
    return [StationeryTemplateCategoryEnum.saveTheDate, StationeryTemplateCategoryEnum.invitation].reduce(
      (acc, category) => {
        const baseUrl = new URL(`/${eventHandle}/edit/card`, window.origin);
        const searchParams = baseUrl.searchParams;
        searchParams.append('ctgy', category);
        if (themeId) searchParams.append('themeId', themeId);

        acc[category] = baseUrl.pathname + baseUrl.search;

        return acc;
      },
      { saveTheDate: '', invitation: '' } as UrlMap
    );
  }, [eventHandle, themeId]);

  const shouldShowMatchingTemplatesView = themeId && printConfig && (!!printConfig.invitation || !!printConfig.saveTheDate);

  return (
    <SkeletonGroup
      isReady={!loading}
      display="flex"
      width={{ _: '328px', lg1: '540px' }}
      maxWidth="100%"
      maxHeight="60vh"
      height="719px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowGap={5}
    >
      {shouldShowMatchingTemplatesView ? (
        <MatchingTemplates onRedirectCtaClick={onRedirectCtaClicked} urls={urls} printConfig={printConfig} />
      ) : (
        <NonMatchingTemplates onRedirectCtaClick={onRedirectCtaClicked} urls={urls} />
      )}
    </SkeletonGroup>
  );
};
