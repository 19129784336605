import { interpolate, OpaqueInterpolation, useSpring } from 'react-spring';
import BezierEasing from 'bezier-easing';
import { useEffect, useRef } from 'react';
import debounce from 'lodash-es/debounce';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { DesignLayoutType, GuestSiteTargetEnum } from '@graphql/generated';
import globalWindow from '@shared/core/globals';
import qs from 'query-string';
import { WebsitePreviewRenderContext } from './WebsitePreview';
import { PreviewDevice } from '@apps/admin/routes/WebsiteDesignerV2/WebsiteDesigner.types';
import { BLISS_PREVIEW_ASPECT_RATIO, PREVIEW_DEVICE_DIMENSIONS } from '@apps/admin/routes/WebsiteDesignerV2/WebsiteDesigner.constants';

const BLISS_PREVIEW_DESKTOP_DIMENSIONS: {
  width: number;
  height: number;
} = {
  width: 1440,
  height: 620
};

type PrimaryAnimationSpring = {
  scale: number;
  width: number;
  height: number;
};

type UseResizerArgs = {
  changePreviewDevice: (previewDevice: PreviewDevice) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  currentLayoutType: DesignLayoutType;
  leftOffset: number;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  isMobile: boolean;
  previewDevice: PreviewDevice;
};

const easing = BezierEasing(0.23, 1, 0.32, 1);
const hasSpringCrossedThreshold = (targetHeight: number, springHeight: number) => Math.abs(targetHeight - springHeight) <= 0.35;

export const usePreviewIframe = ({ changePreviewDevice, containerRef, currentLayoutType, iframeRef, isMobile, leftOffset, previewDevice }: UseResizerArgs) => {
  // Currently, we will drive animation by adding/removing class names - this will happen
  // outside of React's render cycle.
  // We want to track the middle most value of the animated springs. "Middle most" is referring
  // to the CSS property that will finish interpolating 2nd among the 3.
  //
  // In this case,
  //    - 'scale' value range is between 0 and 1
  //    - 'width' value range is [0, 900]
  //    - 'height' value range is [0, 717]
  //  'height' will resolve its interpolaton after 'scale' and before 'width'.
  const prevInterpolatedSpring = useRef({ next: 0, value: 0, isTransitioning: false });

  const debouncedReveal = useEventCallback(
    debounce((show: boolean) => {
      requestAnimationFrame(() => {
        iframeRef.current?.classList.toggle('visible', show);
      });
    })
  );

  const toggleIframeDisplay = useEventCallback((show: boolean) => {
    iframeRef.current?.classList.toggle('shown', show);

    if (show) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          debouncedReveal(true);
        });
      }, 300);
    }
  });

  // @ts-ignore
  const [springProps, setContainerScale, stop] = useSpring<PrimaryAnimationSpring>(() => ({
    scale: 0,
    width: 0,
    height: 0,
    config: {
      clamp: true,
      duration: 1000,
      easing
    },
    // Track the animation as the springs are being interpolated - this will grant finer grain access to its state
    // step by step
    onFrame: (springValue: PrimaryAnimationSpring) => {
      //
      if (hasSpringCrossedThreshold(prevInterpolatedSpring.current.next, springValue.height) && prevInterpolatedSpring.current.isTransitioning) {
        prevInterpolatedSpring.current.isTransitioning = false;

        debouncedReveal(false);
        setTimeout(() => {
          toggleIframeDisplay(true);
        });
      } else if (springValue.height !== prevInterpolatedSpring.current.value) {
        prevInterpolatedSpring.current.value = springValue.height;
      }
    }
  }));

  const resize = useEventCallback((shouldAnimateInOrEvent: boolean | { disableInitialReveal?: boolean } | Event = false) => {
    if (containerRef.current && containerRef.current.parentElement) {
      if (containerRef.current && containerRef.current.parentElement) {
        let { clientWidth, clientHeight } = containerRef.current.parentElement;
        const windowWidth = globalWindow.innerWidth || 0;

        // Workaround for firefox not properly calculating 100% width.
        if (windowWidth >= 769) {
          clientWidth = Math.min(windowWidth - leftOffset - 48, clientWidth);
        }

        let { width: nextWidth, height: nextHeight } = PREVIEW_DEVICE_DIMENSIONS[previewDevice];

        // Bliss preview dimensions are slightly larger than the preview from design.
        if ((!isMobile || currentLayoutType === DesignLayoutType.brannan) && previewDevice === 'desktop') {
          const previewWidth = BLISS_PREVIEW_DESKTOP_DIMENSIONS.width;
          nextHeight = previewWidth / BLISS_PREVIEW_ASPECT_RATIO;
          nextWidth = previewWidth;
          clientHeight = Math.min(clientHeight, PREVIEW_DEVICE_DIMENSIONS.desktop.height);
        }

        const widthScale = clientWidth / nextWidth;
        const heightScale = clientHeight / nextHeight;
        // Choose the most affected scale as the next size
        const scale = Math.min(Math.min(widthScale, heightScale), 1);

        if (springProps.scale.getValue() === scale) {
          // no op
          return;
        }

        const initiateSpring = () => setContainerScale({ scale, width: nextWidth, height: nextHeight });

        if (shouldAnimateInOrEvent instanceof Event || shouldAnimateInOrEvent === false) {
          initiateSpring();
          changePreviewDevice(previewDevice);
        } else {
          // const { disableInitialReveal = false } = (typeof shouldAnimateInOrEvent === 'boolean' ? {} : shouldAnimateInOrEvent) as ResizeAnimationOptions;
          // This call will trigger a series of animations

          // Transition opacity of iframe to 0
          debouncedReveal(false);
          prevInterpolatedSpring.current.next = nextHeight;
          prevInterpolatedSpring.current.isTransitioning = true;

          setTimeout(() => {
            requestAnimationFrame(() => {
              // Concurrently run various animations so they seemlessly flow together

              // 1. Initiate animating preview container to reflect new preview device dimensions
              initiateSpring();

              // 2. Initiate animating new preview container to reflect new preview device styles (border radius, padding, etc)
              changePreviewDevice(previewDevice);

              // 3. Now that the iframe is no longer visible, hide it entirely to improve transition perf (avoid triggering layout within iframe
              //    by hiding the iframe entirely)
              toggleIframeDisplay(false);
            });
          }, 250);
        }
      }
    }
  });

  ///////////////////////////////////////////////

  useEffect(() => {
    window.addEventListener('resize', resize, false);
    return () => {
      window.removeEventListener('resize', resize, false);
    };
  }, [resize]);

  return {
    containerRef,
    iframeRef,
    resize,
    toggleIframeDisplay,

    // react-spring props
    springProps: {
      scale: springProps.scale as OpaqueInterpolation<number>,
      height: springProps.height as OpaqueInterpolation<number>,
      width: springProps.width as OpaqueInterpolation<number>
    }
  } as const;
};

export const constructPreviewUrl = (
  origin: string,
  eventHandle: string,
  renderContext: 'websiteDesigner' | 'adminGuestSitePreview',
  designGuestSiteTarget: GuestSiteTargetEnum,
  enableloadingstyleapplicator = false
) => {
  // Manually constructing a URL instead of using `URL` API because origin is not guaranteed to be defined
  const url = `${designGuestSiteTarget === 'bliss' && process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : origin}/${eventHandle}?${qs.stringify({
    preview: true,
    ctx: renderContext,
    target: designGuestSiteTarget,
    'feature.enableloadingstyleapplicator': enableloadingstyleapplicator, //https://github.com/joylifeinc/joy-web/pull/3214
    'feature.nossr': renderContext === 'websiteDesigner' ? true : undefined
  })}`;

  return url;
};

export const deriveGuestSiteTargetFromRenderContext = (renderContext: WebsitePreviewRenderContext, target: GuestSiteTargetEnum): GuestSiteTargetEnum => {
  return renderContext === 'adminGuestSitePreview' ? GuestSiteTargetEnum.bliss : target;
};

export const calculateBottomActionStyle = (
  previewDevice: PreviewDevice,
  { scale, height, width }: { scale: OpaqueInterpolation<number>; height: OpaqueInterpolation<number>; width: OpaqueInterpolation<number> }
) => {
  return {
    bottom: interpolate([scale, height], (scale, height) => `calc(50% - ${(scale * height) / 2 + 48}px)`),
    width: interpolate([scale, width], (scale, width) => `${width * scale}px`),
    maxWidth: '100%'
  };
};
