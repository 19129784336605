import { useImmer } from 'use-immer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSprings, useTransition } from 'react-spring';
import { DesignLayoutType, useOfferEventSuggestionsMutation, useUpdateEventSuggestionMutation, AdminDashboardPreviewPanelQueryResult, EventType } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { PreviewDevice, PreviewOption } from '@website-designer-v2/WebsiteDesigner.types';
import { createPreviewMessageEventHandler, sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import globalWindow from '@shared/core/globals';
import { getMissingSuggestionIdentifiers } from '@apps/suggestions/components/AdminFeed/AdminFeed.utils';
import { OnCardInteract, ActiveSuggestionIdentifiers } from '@apps/suggestions/components/SuggestionCard';
import { deriveNextCardStateFromInteraction, fadeColor, getContrastText, ColorRGBA, emphasizeColor, colorToCss } from './DashboardPreview.utils';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import { useMutationObserver } from './useMutationObserver';
import { debounce } from 'lodash-es';
import { useDashboardPreviewTelemetry } from './DashboardPreview.telemetry';
import { useThemeUsesStyleApplicator } from '@shared/utils/hooks/useThemeUsesStyleApplicator';

import { HandleShareDialogOpen } from '@apps/admin/routes/Dashboard/Dashboard';
import { FeatureSetType } from '@shared/core/featureSet/featureSet.types';
import { useFeatureSet } from '@shared/core/featureSet/useFeatureSet';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { monorepoRoutePaths, routePaths } from '@apps/admin/route.paths';
import { useHistory } from '@react-router';
import { performFullPageRedirect } from '@shared/utils/navigation';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventType } from '@shared/utils/eventType';

type UseDashboardPreviewControllerArgs = Readonly<
  Pick<AdminDashboardPreviewPanelQueryResult, 'loading' | 'data' | 'refetch'> & { eventHandle: string; handleShareDialogOpen?: HandleShareDialogOpen }
>;

export type DashboardPreviewTab = 'cardFeed' | 'websitePreview' | 'invitesAndPaper';

type State = {
  activeTab: DashboardPreviewTab;
  enablePhotoRepositionFeature: boolean;
  isScrollBarVisible: boolean;
  hasInteractedWithPanelTabsOnce: boolean;
  hasEnsuredEventSuggestions: boolean;
  hasShownInitialLoaderOnce: boolean;
  hasViewedWebsitePreviewTabOnce: boolean;
  previewDevice: PreviewDevice;
};

const tabToIndex: ReadonlyRecord<DashboardPreviewTab, number> = {
  cardFeed: 0,
  websitePreview: 1,
  invitesAndPaper: 2
};

export const useDashboardPreviewController = (args: UseDashboardPreviewControllerArgs) => {
  const { loading, data, refetch, eventHandle, handleShareDialogOpen } = args;
  const { hasFeatureSetEnable, isInitialized: featureSetInitialized } = useFeatureSet();
  const { auth0Id } = useEventUserRole();
  const isWeddingEvent = useEventType().eventType === EventType.wedding;
  const history = useHistory();
  const telemetry = useDashboardPreviewTelemetry();
  const isAdminDashboardChecklistEnabled = useFeatureValue('AdminDashboardChecklist').value === 'treatment';
  const isPrintCardBannerVisible = useFeatureValue('printCardBannerEnabled').value === 'admindashboard';

  const [offerEventSuggestionsMutation, { called: hasCalledOfferEventSuggestions }] = useOfferEventSuggestionsMutation({
    onCompleted: data => {
      refetch();
    },
    onError: err => {
      telemetry.offerEventSuggestionsError(err);
    }
  });
  const [updateEventSuggestionMutation] = useUpdateEventSuggestionMutation({
    onError: err => {
      telemetry.updateEventSuggestionsError(err);
    }
  });
  const [
    {
      activeTab,
      enablePhotoRepositionFeature,
      hasEnsuredEventSuggestions,
      hasInteractedWithPanelTabsOnce,
      hasShownInitialLoaderOnce,
      hasViewedWebsitePreviewTabOnce,
      isScrollBarVisible,
      previewDevice
    },
    setState
  ] = useImmer<State>(() => ({
    activeTab: 'cardFeed',
    enablePhotoRepositionFeature: false,
    isScrollBarVisible: false,
    hasEnsuredEventSuggestions: false,
    hasInteractedWithPanelTabsOnce: false,
    hasShownInitialLoaderOnce: false,
    hasViewedWebsitePreviewTabOnce: true,
    previewDevice: 'desktop'
  }));

  const [isCompactMode, setIsCompactMode] = useState(false);
  const [isScreenMobile, setIsScreenMobile] = useState(false);
  const [hasWebsite, setHasWebsite] = useState(true);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  /**
   * Set the activeTab depending on the FeatureSet
   */
  useEffect(() => {
    const websiteEnable = hasFeatureSetEnable(FeatureSetType.Website);
    setState(draft => {
      draft.activeTab = 'websitePreview';
    });
    setHasWebsite(websiteEnable);
  }, [featureSetInitialized, hasFeatureSetEnable, isCompactMode, setState]);

  const isReadyForUse = !loading && hasEnsuredEventSuggestions && featureSetInitialized;
  const eventId = data?.eventByName?.id;
  const firebaseId = data?.eventByName?.firebaseId;
  const suggestions = data?.eventByName?.suggestion.items;
  const eventColorRgba = data?.eventByName?.style.primaryColor.rgba;
  const { layout: currentLayoutType, theme: currentThemeId } = data?.eventByName?.style || {};
  const updateContext = telemetry.updateContext;
  const eventPassword = data?.eventByName?.info.eventPassword ?? '';
  const guestSiteLink = `${window.location.origin}/${eventHandle}`;

  const setScrollbarVisibilityFromTarget = useEventCallback(
    debounce((element: Maybe<Element>) => {
      setState(draft => {
        const hasOverflow = element ? element.scrollHeight > element.clientHeight : false;
        draft.isScrollBarVisible = hasOverflow;
      });
    }, 300)
  );

  const themeNotSupported = useThemeUsesStyleApplicator(currentThemeId) === false;

  //////////////////////////////
  // Animations

  const [contentContainerSprings, setSprings] = useSprings<{ x: number; opacity: number }>(2, i => ({
    x: i * (globalWindow.innerWidth || 0),
    opacity: i === tabToIndex.cardFeed ? 1 : 0,
    config: {
      clamp: true
    },
    onStart: () => {
      if (i === tabToIndex.websitePreview) {
        // Automatically hide
        setState(draft => {
          draft.isScrollBarVisible = false;
        });
      }
    },
    onRest: props => {
      if (props.opacity === 1) {
        setScrollbarVisibilityFromTarget(contentWrapperRef.current);
      }
    }
  }));

  // Show loader and transition to content once checks have been performed
  const rootTransitions = useTransition(hasShownInitialLoaderOnce, null, {
    from: { position: 'absolute', height: '100vh', width: '100%', opacity: 0 },
    enter: { opacity: 1, position: 'relative' },
    leave: { opacity: 0, position: 'absolute' }
  });

  //////////////////////////////
  // Utils

  const switchToPanelTab = useEventCallback((tab: DashboardPreviewTab, source: 'message' | 'click') => {
    if (tab === activeTab) {
      return;
    }

    if (source === 'click') {
      telemetry.toggleViewClick(tab);
    }

    const tabIndex = tabToIndex[tab];
    const directionMultiplier = tabIndex === 0 ? 1 : -1;

    // maintain compact mode on page refresh to handle /edit/welcome page case
    // but should not switch to compact mode if clicking on tabs when welcome is visible
    if (tab === 'websitePreview' && source !== 'click') {
      setIsCompactMode(true);
    }

    setState(draft => {
      // Prevent switching to website tab if it doesn't exist
      draft.activeTab = tab === 'invitesAndPaper' ? tab : hasWebsite || isCompactMode ? tab : 'cardFeed';
      draft.hasInteractedWithPanelTabsOnce = true;
      draft.hasViewedWebsitePreviewTabOnce = draft.hasViewedWebsitePreviewTabOnce || tab === 'websitePreview';
    });

    if (contentWrapperRef.current) {
      contentWrapperRef.current.scrollTop = 0;
    }

    setSprings(((i: number) => {
      if (i !== tabIndex) return { opacity: 0, x: (globalWindow.innerWidth || 0) * directionMultiplier };
      return { x: 0, opacity: 1 };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any);
  });

  const derivedColorsFromEvent = useMemo(() => {
    let background = '#fff';
    let backgroundTranslucent = '#fff';
    let backgroundEmphasized = emphasizeColor(background);
    let foreground = '#333';
    let foregroundTranslucent = 'rgba(0, 0, 0, 0.3)';
    if (eventColorRgba) {
      background = colorToCss(eventColorRgba as ColorRGBA);
      backgroundTranslucent = fadeColor(eventColorRgba as ColorRGBA, 0.6);
      backgroundEmphasized = emphasizeColor(eventColorRgba as ColorRGBA);
      foreground = getContrastText(background);
      foregroundTranslucent = fadeColor(emphasizeColor(foreground), 0.4);
    }

    return {
      background,
      backgroundEmphasized,
      backgroundTranslucent,
      foreground,
      foregroundTranslucent
    };
  }, [eventColorRgba]);

  const getComponentColorsFromState = () => {
    const isViewingCardFeed = activeTab === 'cardFeed';
    return {
      rootBackground: hasShownInitialLoaderOnce ? '#fff' : undefined,
      containerBackground: hasShownInitialLoaderOnce ? (isViewingCardFeed ? derivedColorsFromEvent.backgroundTranslucent : derivedColorsFromEvent.background) : undefined,
      panelTabOutline: isViewingCardFeed ? derivedColorsFromEvent.background : derivedColorsFromEvent.backgroundEmphasized
    };
  };

  const notifyTopOfInitialization = useEventCallback(() => {
    sendMessageToParentWindow({
      action: 'dashboardPanelInitialized',
      value: { hasUserInteractedWithPanelTabs: hasInteractedWithPanelTabsOnce },
      source: 'joyPreview'
    });
  });

  //////////////////////////////
  // Event handlers

  const handleOnViewSiteClick = useEventCallback(() => {
    telemetry.guestSiteViewOpen();
    window.open(`/${eventHandle}`, '_blank');
  });
  const handleOnShareClick = useEventCallback(() => {
    telemetry.shareDialogOpen();
    handleShareDialogOpen && handleShareDialogOpen({ eventPassword, guestSiteLink });
  });
  const handleOnRepositionPhotoClick = useEventCallback(() => {
    sendMessageToParentWindow({
      action: 'previewActionInteraction',
      value: 'repositionPhotoButton',
      source: 'joyPreview'
    });
  });

  const handleOnLoaderAnimateStop = useEventCallback(() => {
    setState(draft => {
      draft.hasShownInitialLoaderOnce = true;
    });
  });

  const handleOnPreviewToggleClick = useEventCallback((previewOption: PreviewOption) => {
    telemetry.previewDeviceToggle(previewOption);
    setState(draft => {
      draft.previewDevice = previewOption;
    });
  });

  const handleOnTabClick = useEventCallback(
    (tab: DashboardPreviewTab): React.MouseEventHandler => {
      return () => {
        switchToPanelTab(tab, 'click');
      };
    }
  );

  //links for redirecting to monorepo
  const CARD_LINKS_FULL_REDIRECT = {
    writersblock: `/${monorepoRoutePaths.writersblock}`,
    'email-verification': `${monorepoRoutePaths.accountVerifyEmail}`,
    'onboarding-add-page-photos': `/${eventHandle}/edit/${monorepoRoutePaths.photos}`,
    'onboarding-invite-admin': `/${eventHandle}/edit/${monorepoRoutePaths.settingsAccount}`,
    'onboarding-add-domain': `/${eventHandle}/edit/${monorepoRoutePaths.settings}`,
    'onboarding-learn-virtual-event': '/blog/what-to-include-on-wedding-website-for-virtual-wedding/',
    'onboarding-browse-insurance': `/wedding-insurance/?mode=chromeless&event_id=${eventId}&user_id=${auth0Id}&platform=web`
  };

  //links within Charm
  const CARD_LINKS_CHARM = {
    'new-update-designs-2020-01-14': `/${eventHandle}/edit/${routePaths.design.path}`,
    'reg-add-first-gift': `/${eventHandle}/edit/${routePaths.shop.path}`,
    'reg-browse-cash-funds': `/${eventHandle}/edit/${routePaths.shop.path}/catalog/cash-funds`,
    'reg-connect-registries': `/${eventHandle}/edit/${routePaths.registryManage.path}?linkRegistryDialogOpen=true`,
    'reg-shop': `/${eventHandle}/edit/${routePaths.shop.path}`,
    'reg-get-started': `/${eventHandle}/edit/${routePaths.registryManage.path}`,
    'reg-discount': `/${eventHandle}/edit/${routePaths.shop.path}`,
    'reg-manage-still-needed': `/${eventHandle}/edit/${routePaths.registryManage.path}?filter=stillNeeded`,
    'reg-manage-reserved-and-purchased': `/${eventHandle}/edit/${routePaths.registryManage.path}?filter=reservedAndPurchased`,
    'onboarding-designs': `/${eventHandle}/edit/${routePaths.design.path}`
  };

  const closeCard = (args: Parameters<OnCardInteract>[0]): void => {
    const { id, identifier } = args;
    const nextStateJson = deriveNextCardStateFromInteraction(args);

    updateEventSuggestionMutation({
      variables: { id, payload: { stateJSON: nextStateJson } },
      optimisticResponse: {
        updateEventSuggestion: {
          __typename: 'EventSuggestion',
          // just dummy data till the response comes back
          createdAt: {
            __typename: 'Date',
            timestamp: 12345678,
            timezone: 'pst',
            milliseconds: 1
          },
          offeredAt: {
            __typename: 'Date',
            timestamp: 12345678,
            timezone: 'pst',
            milliseconds: 1
          },
          // actual optimistic data till the response comes back
          identifier,
          id,
          stateJSON: nextStateJson
        }
      }
    });

    telemetry.suggestionClose(identifier);
  };

  const handleOnSuggestionCardInteract = useEventCallback<OnCardInteract>(args => {
    const { identifier, interaction, source } = args;

    if (source === 'cardCloseButtonBeforePoll') {
      telemetry.suggestionClose(identifier);
      return;
    }

    if (interaction === 'use' && source === 'cardAction') {
      telemetry.suggestionClick(identifier);
      if (!!CARD_LINKS_FULL_REDIRECT[identifier as keyof typeof CARD_LINKS_FULL_REDIRECT]) {
        performFullPageRedirect(CARD_LINKS_FULL_REDIRECT[identifier as keyof typeof CARD_LINKS_FULL_REDIRECT]);
      } else if (!!CARD_LINKS_CHARM[identifier as keyof typeof CARD_LINKS_CHARM]) {
        history.push(CARD_LINKS_CHARM[identifier as keyof typeof CARD_LINKS_CHARM]);
      } else {
        sendMessageToParentWindow({
          action: 'suggestionCardInteraction',
          value: identifier as ActiveSuggestionIdentifiers,
          source: 'joyPreview'
        });
      }
    } else if (interaction === 'decline' && source === 'cardCloseButton') {
      closeCard(args);
    } else if (source === 'closingPoll') {
      telemetry.pollResponseClick(identifier, interaction);
    } else if (source === 'autoClose') {
      telemetry.suggestionAutoClose(identifier);
    }
  });

  const handleOnViewAllCardsClick = useEventCallback(() => {
    telemetry.viewAllSuggestionsClick();
  });

  //////////////////////////////
  // Lifecycle

  useEffect(() => {
    telemetry.viewDisplayed(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    updateContext(prev => ({ ...prev, eventId: firebaseId }));
  }, [firebaseId, updateContext]);

  // Ensure that the user has all of the expected event suggestions, backfilling missing suggestions
  useEffect(() => {
    if (eventId && suggestions) {
      const missingSuggestionIdentifiers = getMissingSuggestionIdentifiers(suggestions);
      if (missingSuggestionIdentifiers.length) {
        if (hasCalledOfferEventSuggestions) {
          // TODO: Add telemetry, figure out why we can't add instead of retrying
          return;
        }
        offerEventSuggestionsMutation({
          variables: {
            eventId,
            identifiers: missingSuggestionIdentifiers as string[]
          }
        });
      } else {
        setState(draft => {
          draft.hasEnsuredEventSuggestions = true;
        });
      }
    }
  }, [eventId, hasCalledOfferEventSuggestions, offerEventSuggestionsMutation, suggestions, setState]);

  // Add a reduced preview message bus subscriber that will handle dashboard navigate/preview device changes
  useEffect(() => {
    const receiveMessage = createPreviewMessageEventHandler(message => {
      if (message.action === 'fromList') {
        message.value.forEach(result => {
          switch (result.key) {
            case 'switchToPanelTab':
              // Origin might want to render a specific tab for a given admin page
              switchToPanelTab(result.value, 'message');
              break;
            case 'switchToPreviewDevice':
              handleOnPreviewToggleClick(result.value);
              break;
            case 'isCompactMode':
              setIsCompactMode(result.value);
              break;
            case 'isScreenMobile':
              setIsScreenMobile(result.value);
              break;
            case 'setWelcomePageCompact':
              // this is set when a user navigates between tasks, as we cannot set welcome page as compact by default (it is used in the dasboard)
              setIsCompactMode(result.value);
              break;
            case 'enablePhotoRepositionFeature':
              setState(draft => {
                draft.previewDevice = result.value.previewDevice || (result.value.isEnabled ? 'mobile' : 'desktop');
                draft.enablePhotoRepositionFeature = result.value.isEnabled;
              });
              break;
          }
        });
      }
    });
    globalWindow.addEventListener?.('message', receiveMessage, false);
    notifyTopOfInitialization();

    return () => {
      globalWindow.removeEventListener?.('message', receiveMessage, false);
    };
  }, [handleOnPreviewToggleClick, notifyTopOfInitialization, setState, switchToPanelTab]);

  // Dismiss verify email card if email is verified
  useEffect(() => {
    const emailVerified = data?.me?.emailIsVerified;
    const verifyEmailSuggestion = suggestions?.find(suggestion => suggestion.identifier === ('email-verification' as ActiveSuggestionIdentifiers));
    // if it has stateJSON it means it's been processed previously
    if (emailVerified && verifyEmailSuggestion && !verifyEmailSuggestion.stateJSON) {
      handleOnSuggestionCardInteract({
        id: verifyEmailSuggestion.id,
        identifier: verifyEmailSuggestion.identifier,
        interaction: 'decline',
        source: 'autoClose',
        stateJSON: verifyEmailSuggestion.stateJSON
      });
    }
  }, [data?.me?.emailIsVerified, handleOnSuggestionCardInteract, suggestions]);

  const contentElement = contentWrapperRef.current;

  useIsomorphicLayoutEffect(() => {
    setScrollbarVisibilityFromTarget(contentElement);
  }, [contentElement, setScrollbarVisibilityFromTarget]);

  useMutationObserver(contentWrapperRef, { subtree: true, childList: true }, entry => {
    if (entry.type === 'childList') {
      setScrollbarVisibilityFromTarget(contentWrapperRef.current as Element);
    }
  });

  return {
    activeTab,
    colors: derivedColorsFromEvent,
    componentColors: getComponentColorsFromState(),
    contentContainerSprings,
    contentWrapperRef,
    currentLayoutType: (currentLayoutType as unknown) as DesignLayoutType | undefined,
    currentThemeId,
    enablePhotoRepositionFeature,
    eventColorRgba,
    hasShownInitialLoaderOnce,
    hasViewedWebsitePreviewTabOnce,
    isReadyForUse,
    isScrollBarVisible,
    isChecklistVisible: isAdminDashboardChecklistEnabled && isWeddingEvent,
    isPrintCardBannerVisible,
    previewDevice,
    rootTransitions,
    suggestions: suggestions || [],
    isCompactMode,
    isScreenMobile,
    guestSiteLink,
    eventPassword,
    hasWebsite,
    // Handlers
    handleOnLoaderAnimateStop,
    handleOnPreviewToggleClick,
    handleOnRepositionPhotoClick,
    handleOnShareClick,
    handleOnSuggestionCardInteract,
    handleOnTabClick,
    handleOnViewAllCardsClick,
    handleOnViewSiteClick,
    handlePrintTipClick: telemetry.printSmartTipClicked,
    themeNotSupported
  } as const;
};
