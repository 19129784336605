import React from 'react';
import { Flex, Box, TextV2, FlexProps } from '@withjoy/joykit';
import { useDashboardPreviewStationeryTemplatesQuery } from '@graphql/generated';
import { Skeleton } from '@shared/components/Skeleton';
import { useHistory, useTranslation } from '@shared/core';
import { useEventInfo } from '@shared/utils/eventInfo';

interface PrintSmartTipProps extends Omit<FlexProps, 'onClick'> {
  themeId: string;
  onClick?: (isMatching: boolean) => void;
}

const PrintSmartTip: React.FC<PrintSmartTipProps> = ({ themeId, onClick, ...props }) => {
  const { t } = useTranslation('websiteDesigner');
  const { data, loading } = useDashboardPreviewStationeryTemplatesQuery({ variables: { themeId }, batchMode: 'fast', skip: !themeId });
  const { eventHandle } = useEventInfo();
  const history = useHistory();

  const stationeryTemplate = data?.stationeryTemplates?.[0];

  const handleButtonClick = () => {
    onClick?.(!!stationeryTemplate);
    history.push(`/${eventHandle}/edit/card${stationeryTemplate ? `?theme=${stationeryTemplate.themeId}&ctgy=${stationeryTemplate.category}&pdp=true` : ''}`);
  };

  if (loading) {
    return <Skeleton height={144} width="100%" maxWidth="470px" borderRadius={3} />;
  }

  return (
    <Flex
      width="100%"
      maxWidth="470px"
      padding={4}
      gap={4}
      borderRadius={3}
      alignItems="center"
      backgroundColor="white"
      boxShadow="0px 4px 16px 0px #00000012, 0px 7px 27px 0px #2C29250F"
      {...props}
    >
      <Box as="img" height="125px" src={!!stationeryTemplate ? stationeryTemplate.thumbnailUrl || '' : 'https://withjoy.com/assets/public/print-smart-tip-browse.png'} />
      <Flex flexDirection="column" paddingY={2}>
        <TextV2 typographyVariant="label1" color="mono12">
          {t('smartTip').label()}
        </TextV2>
        <TextV2 typographyVariant="hed2" marginTop={2}>
          {!!stationeryTemplate ? t('smartTip').matching() : t('smartTip').browse()}
        </TextV2>
        <Box
          alignSelf="flex-start"
          cursor="pointer"
          typographyVariant="button1"
          fontWeight={600}
          borderBottom="1px solid #EBEBEB"
          borderRadius={0}
          lineHeight={1}
          paddingY={1}
          marginTop={5}
          __css={{ _hover: { borderBottomColor: 'transparent' } }}
          onClick={handleButtonClick}
        >
          {!!stationeryTemplate ? t('smartTip').matchingButton() : t('smartTip').browseButton()}
        </Box>
      </Flex>
    </Flex>
  );
};

export default PrintSmartTip;
