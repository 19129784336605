import React, { useEffect } from 'react';
import { animated } from 'react-spring';
import { SketchedJoyLogo } from '@apps/admin/common/SketchedJoyLogo/SketchedJoyLogo';
import { Box, Flex, IconV2, styled } from '@withjoy/joykit';
import { Envelope } from '@withjoy/joykit/icons';
import {
  tabButtonOverrides,
  StyledRoot,
  StyledContainer,
  // StyledContentTransitionBox,
  StyledAdminFeed,
  StyledContentWrapper,
  StyledPseudoButton,
  StyledTabButton,
  StyledWebsitePreviewContainer,
  StyledPreviewTogglesBox,
  StyledTabButtonsContainer
} from './DashboardPreview.styles';
import { NewWindow, Share } from '@withjoy/joykit/icons';
import { useDashboardPreviewController } from './DashboardPreview.controller';
import { useAdminDashboardPreviewPanelQuery, AdminDashboardPreviewPanelQueryResult } from '@graphql/generated';
import { TelemetryProvider } from './DashboardPreview.telemetry';
import { SlideInTransition } from '@shared/components';
import { Suspense } from '@shared/core/react/Suspense';
import { PreviewToggles } from '@website-designer-v2/components/DesignPreview/components/PreviewToggles';
import { PreviewTogglesWrapper } from '@website-designer-v2/components/DesignPreview/DesignPreview.styles';
import { WebsitePreview } from '@website-designer/components/WebsitePreview';
import { HandleShareDialogOpen } from '@apps/admin/routes/Dashboard/Dashboard';
import { useDashboardPreviewHealth, wrapWithDashboardPreviewHealthProvider } from '@apps/admin/routes/DashboardPreview/DashboardPreview.health';
import { useFeatureSet } from '@shared/core/featureSet/useFeatureSet';
import { PreviewOption } from '../WebsiteDesignerV2/WebsiteDesigner.types';
import { JoyStationery } from './components/JoyStationery/JoyStationery';
import PrintSmartTip from '@apps/admin/components/PrintSmartTip';
import { DashboardChecklist } from './components/DashboardChecklist';
import { ParticleJoyLogo } from '@shared/components/ParticleJoyLogo';

type DashboardPreviewTab = 'cardFeed' | 'websitePreview';

type DashboardPreviewProps = Readonly<{
  eventHandle: string;
  shouldRefreshDashboardPreview?: boolean;
  /**
   * Value to determine if the preview is being shown in the site preview context (for example, when editing pages)
   */
  isSitePreview?: boolean;
}> & { handleShareDialogOpen?: HandleShareDialogOpen };

export const StyledParticleJoyLogo = styled(ParticleJoyLogo)`
  padding: 0;
  cursor: default;
  svg,
  canvas {
    height: 56px;
    width: inherit;
  }
`;

export const DashboardPreview: React.FC<
  Pick<AdminDashboardPreviewPanelQueryResult, 'loading' | 'data' | 'refetch'> &
    Pick<DashboardPreviewProps, 'isSitePreview'> & { eventHandle: string; handleShareDialogOpen?: HandleShareDialogOpen }
> = props => {
  const {
    activeTab,
    colors,
    componentColors,
    contentWrapperRef,
    currentLayoutType,
    currentThemeId,
    enablePhotoRepositionFeature,
    hasViewedWebsitePreviewTabOnce,
    isReadyForUse,
    isScrollBarVisible,
    isChecklistVisible,
    isPrintCardBannerVisible,
    previewDevice,
    rootTransitions,
    suggestions,
    isCompactMode,
    isScreenMobile,
    hasWebsite,
    // handlers
    handleOnLoaderAnimateStop,
    handleOnRepositionPhotoClick,
    handleOnPreviewToggleClick,
    handleOnSuggestionCardInteract,
    handleOnShareClick,
    handleOnViewAllCardsClick,
    handleOnViewSiteClick,
    handleOnTabClick,
    handlePrintTipClick,
    themeNotSupported
  } = useDashboardPreviewController(props);

  const PseudoButtonsGroup = enablePhotoRepositionFeature ? (
    <Flex width="100%" justifyContent="center">
      <StyledPseudoButton role="button" onClick={handleOnRepositionPhotoClick}>
        Reposition Photo
      </StyledPseudoButton>
    </Flex>
  ) : (
    <Flex justifyContent="space-between" paddingX={6} width="100%">
      <StyledPseudoButton role="button" onClick={handleOnViewSiteClick}>
        <NewWindow size="md" marginRight={4} />
        View Site
      </StyledPseudoButton>

      <StyledPseudoButton role="button" onClick={handleOnShareClick}>
        <Share size="md" marginRight={4} />
        Share
      </StyledPseudoButton>
    </Flex>
  );

  const content = [
    <StyledAdminFeed
      key="adminfeed"
      $bgColorEmphasized={colors.background}
      $fgColor={colors.foreground}
      onInteract={handleOnSuggestionCardInteract}
      onViewAllClick={handleOnViewAllCardsClick}
      suggestions={suggestions}
    />,
    <>
      {isChecklistVisible && props.data?.eventByName?.checklist?.id && !props.isSitePreview ? (
        <Flex rowGap={7} paddingBottom={150} flexDirection={'column'} alignItems={'center'} maxWidth={'412px'} margin={'0 auto'}>
          <WebsitePreview
            contentBelow={!isCompactMode && PseudoButtonsGroup}
            currentLayoutType={currentLayoutType}
            disableDomainSearch={true}
            eventHandle={props.eventHandle}
            hasInitializedOnce={hasViewedWebsitePreviewTabOnce}
            hideWhenNotShown={false}
            isMobile={false}
            isShown={true}
            previewDevice={previewDevice}
            renderContext="adminGuestSitePreview"
            themeId={currentThemeId}
            themeNotSupported={themeNotSupported}
            reducedDashboardHeight={true}
          />
          <DashboardChecklist eventHandle={props.eventHandle} />
        </Flex>
      ) : (
        <StyledWebsitePreviewContainer id={'StyledWebsitePreviewContainer'} key="preview" isCompactMode={isCompactMode} isScreenMobile={isScreenMobile}>
          {previewDevice === PreviewOption.STATIONERY ? (
            <JoyStationery themeId={currentThemeId} eventHandle={props.eventHandle} />
          ) : (
            <WebsitePreview
              contentBelow={!isCompactMode && PseudoButtonsGroup}
              currentLayoutType={currentLayoutType}
              disableDomainSearch={true}
              eventHandle={props.eventHandle}
              hasInitializedOnce={hasViewedWebsitePreviewTabOnce}
              hideWhenNotShown={false}
              isMobile={false}
              isShown={true}
              previewDevice={previewDevice}
              renderContext="adminGuestSitePreview"
              themeId={currentThemeId}
              themeNotSupported={themeNotSupported}
            />
          )}
          <StyledPreviewTogglesBox id={'StyledPreviewTogglesBox'} isCompactMode={isCompactMode}>
            <PreviewTogglesWrapper>
              <PreviewToggles isParentMounted={true} onToggleSelect={handleOnPreviewToggleClick} activePreviewOption={previewDevice} />
            </PreviewTogglesWrapper>
          </StyledPreviewTogglesBox>
          {isPrintCardBannerVisible && (
            <PrintSmartTip themeId={currentThemeId || ''} marginBottom={6} onClick={isMatching => handlePrintTipClick(isMatching, currentThemeId || '')} />
          )}
        </StyledWebsitePreviewContainer>
      )}
    </>
  ];

  const getButtonColor = (targetTab: DashboardPreviewTab) => {
    // eslint-disable-next-line
    return activeTab === targetTab ? colors.foreground : (colors.foregroundTranslucent as any);
  };

  const renderContent = () => {
    return (
      <>
        <StyledContentWrapper ref={contentWrapperRef} $isScrollBarVisible={isScrollBarVisible}>
          {!isCompactMode && (
            <StyledTabButtonsContainer>
              <StyledTabButton
                $isActive={activeTab === 'cardFeed'}
                startIcon={() => (
                  <IconV2 size={24}>
                    <defs>
                      <path id="A" d="M0 0h24v24H0z" />
                    </defs>
                    <clipPath id="B">
                      <use xlinkHref="#A" />
                    </clipPath>
                    <g clipPath="url(#B)">
                      <path
                        d="M12 3a.65.65 0 0 1 .6.6v2.8a.65.65 0 0 1-.6.6.65.65 0 0 1-.6-.6V3.6A.65.65 0 0 1 12 3zM4.7 5.6a.61.61 0 0 1 .8 0l2 2a.61.61 0 0 1 0 .8c-.2.3-.5.3-.8 0l-2-2a.61.61 0 0 1 0-.8zm14.6 0a.61.61 0 0 1 0 .8l-2 2a.61.61 0 0 1-.8 0 .61.61 0 0 1 0-.8l2-2c.2-.2.5-.2.8 0zM6.4 13.8H.6a.65.65 0 0 0-.6.6.65.65 0 0 0 .6.6h22.8a.65.65 0 0 0 .6-.6.65.65 0 0 0-.6-.6h-5.8A5.62 5.62 0 0 0 12 8.4a5.62 5.62 0 0 0-5.6 5.4zm1.2 0h8.8c-.1-2.3-2-4.2-4.4-4.2s-4.3 1.9-4.4 4.2zM19 11.6a.65.65 0 0 1 .6-.6h2.8a.65.65 0 0 1 .6.6.65.65 0 0 1-.6.6h-2.8a.65.65 0 0 1-.6-.6zm-18 0a.65.65 0 0 1 .6-.6h2.8a.65.65 0 0 1 .6.6.65.65 0 0 1-.6.6H1.6a.65.65 0 0 1-.6-.6zm2 5.8a.65.65 0 0 1 .6-.6h16.8a.65.65 0 0 1 .6.6.65.65 0 0 1-.6.6H3.6a.65.65 0 0 1-.6-.6zm3 3a.65.65 0 0 1 .6-.6h10.8a.65.65 0 0 1 .6.6.65.65 0 0 1-.6.6H6.6a.65.65 0 0 1-.6-.6z"
                        fillRule="evenodd"
                        fill="currentColor"
                      />
                    </g>
                  </IconV2>
                )}
                overrides={tabButtonOverrides}
                fontSize={13}
                color={getButtonColor('cardFeed')}
                variant="ghost"
                onClick={handleOnTabClick('cardFeed')}
              >
                Next Steps
              </StyledTabButton>
              {hasWebsite && (
                <StyledTabButton
                  $isActive={activeTab === 'websitePreview'}
                  startIcon={() => (
                    <IconV2 size={24}>
                      <path
                        d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zm0-1.2c1.3 0 1.9-2.6 2.2-3.6-1.4-.1-2.9-.1-4.4 0 .3.9.9 3.6 2.2 3.6zM9.6 16c-.2-.9-.3-1.9-.3-3h5.6c0 1.1-.2 2.1-.3 3-1.8-.1-3.4-.1-5 0zm5.8 1.3c-.3 1.3-.8 2.4-1.3 3.2 1.6-.4 3-1.2 4.2-2.3-.5-.2-1.4-.6-2.9-.9zm3.6 0c-.5-.3-1.5-.8-3.4-1.1.2-1 .3-2 .3-3.2h4.8c-.1 1.6-.7 3.1-1.7 4.3zM8.4 16.2c-1.9.3-2.9.8-3.4 1.1-1-1.2-1.6-2.7-1.7-4.3H8c.1 1.1.2 2.2.4 3.2zm-2.6 2c.4-.2 1.3-.6 2.9-.9.3 1.3.8 2.4 1.3 3.2-1.7-.3-3.1-1.2-4.2-2.3zm15-6.4c0-1.9-.7-3.6-1.7-5-1 .8-2.2 1.2-3.4 1.5.2 1.1.3 2.3.3 3.6h4.8zm-6.7-8.4c1.6.4 3.1 1.2 4.2 2.4-.4.3-1.2.8-2.8 1.2-.4-1.4-.8-2.7-1.4-3.6zm.2 3.9a17.71 17.71 0 0 1-4.6 0c.3-1.1.9-4.1 2.3-4.1s2 3 2.3 4.1zm.2 1.1c.2 1 .3 2.2.3 3.4H9.2a18.7 18.7 0 0 1 .3-3.4c1.6.3 3.4.3 5 0zM8 11.8c0-1.3.1-2.5.3-3.6a8.47 8.47 0 0 1-3.4-1.4c-1.1 1.4-1.7 3.2-1.7 5H8zm1.9-8.4c-.6.9-1 2.2-1.4 3.6-1.6-.3-2.4-.9-2.8-1.2 1.1-1.1 2.6-2 4.2-2.4z"
                        fillRule="evenodd"
                        fill="currentColor"
                      />
                    </IconV2>
                  )}
                  color={getButtonColor('websitePreview')}
                  fontSize={13}
                  marginLeft={4}
                  variant="ghost"
                  overrides={tabButtonOverrides}
                  onClick={handleOnTabClick('websitePreview')}
                >
                  Website
                </StyledTabButton>
              )}
              <StyledTabButton
                $isActive={activeTab === 'invitesAndPaper'}
                startIcon={() => <Envelope size={24} />}
                color={getButtonColor('websitePreview')}
                fontSize={13}
                marginLeft={4}
                variant="ghost"
                overrides={tabButtonOverrides}
                onClick={handleOnTabClick('invitesAndPaper')}
              >
                Invitations
              </StyledTabButton>
            </StyledTabButtonsContainer>
          )}
          <Box position={'relative'} height="calc(100vh - 96px)">
            {!isCompactMode && (
              <SlideInTransition from="left" isIn={activeTab === 'cardFeed'} enableHideOnOut={true}>
                {content[0]}
              </SlideInTransition>
            )}
            <SlideInTransition from="right" isIn={activeTab === 'websitePreview'} enableHideOnOut={false}>
              {content[1]}
            </SlideInTransition>
            <SlideInTransition from="right" isIn={activeTab === 'invitesAndPaper'} enableHideOnOut={false}>
              <Flex height="100%" width="100%" justifyContent="center">
                <JoyStationery themeId={currentThemeId} eventHandle={props.eventHandle} />
              </Flex>
            </SlideInTransition>
          </Box>
        </StyledContentWrapper>
      </>
    );
  };

  return (
    <StyledRoot $bgColor={componentColors.rootBackground} flexDirection="column" minHeight="100vh">
      {rootTransitions.map(({ item, props: styleProps }) =>
        item ? (
          <StyledContainer as={animated.div} paddingX={3} key="content" style={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', ...styleProps }}>
            <Suspense fallback={<SketchedJoyLogo containerBgColor="transparent" shouldAnimate={false} />}>{renderContent()}</Suspense>
          </StyledContainer>
        ) : (
          <StyledContainer as={animated.div} key="loader" style={{ position: 'absolute', top: 0, ...styleProps }}>
            <SketchedJoyLogo
              containerBgColor="transparent"
              logoFillFrom="#322E53"
              logoFill={colors.background}
              shouldAnimate={!isReadyForUse}
              onAnimateStop={handleOnLoaderAnimateStop}
            />
          </StyledContainer>
        )
      )}
    </StyledRoot>
  );
};

export const DashboardPreviewProvider: React.FC<DashboardPreviewProps> = wrapWithDashboardPreviewHealthProvider(
  ({ eventHandle, handleShareDialogOpen, shouldRefreshDashboardPreview, isSitePreview }) => {
    const { refetchFeatureSet } = useFeatureSet();
    const { loading, data, error, refetch } = useAdminDashboardPreviewPanelQuery({
      variables: { eventHandle },
      batchMode: 'fast'
    });
    const { onLoadComplete, onLoadFailure } = useDashboardPreviewHealth();

    useEffect(() => {
      if (shouldRefreshDashboardPreview) {
        refetchFeatureSet();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRefreshDashboardPreview]);

    useEffect(() => {
      if (data) {
        onLoadComplete();
      }
      if (error) {
        onLoadFailure('AdminDashboardPreviewPanelQuery failed', error);
      }
    }, [data, error, onLoadComplete, onLoadFailure]);

    return (
      <TelemetryProvider context={{ eventId: data?.eventByName?.firebaseId }}>
        <DashboardPreview isSitePreview={isSitePreview} handleShareDialogOpen={handleShareDialogOpen} eventHandle={eventHandle} loading={loading} data={data} refetch={refetch} />
      </TelemetryProvider>
    );
  }
);
