/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Envelope',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.2 6.05365V18.0602L8.48585 11.8109L2.2 6.05365ZM9.37174 12.6223L3.15791 18.8H21.1902L14.7771 12.6155L13.5005 13.7794C12.6796 14.5279 11.4506 14.5263 10.6314 13.776L9.37174 12.6223ZM15.6657 11.8054L21.8 17.7209V6.21248L15.6657 11.8054ZM21.1294 5.2L12.692 12.8927C12.3301 13.2227 11.8033 13.2221 11.4419 12.8911L3.04465 5.2H21.1294ZM22.2073 4.21722C21.9778 4.07899 21.7119 4 21.4286 4H2.57143C1.70355 4 1 4.74105 1 5.65517V18.3448C1 18.6119 1.06005 18.8642 1.16669 19.0875C1.24036 19.2418 1.33625 19.3823 1.44995 19.5043C1.4738 19.5298 1.49843 19.5546 1.52381 19.5785C1.67458 19.7207 1.85156 19.8326 2.04617 19.9053C2.21042 19.9666 2.38722 20 2.57143 20H21.4286C21.7124 20 21.9787 19.9207 22.2086 19.782C22.2725 19.7435 22.3335 19.7004 22.3914 19.6531C22.4892 19.573 22.5779 19.4811 22.6553 19.3793C22.7808 19.2144 22.877 19.0237 22.9354 18.8159C22.9775 18.6666 23 18.5085 23 18.3448V5.65517C23 5.48953 22.9769 5.32956 22.9339 5.17866C22.8753 4.97298 22.7797 4.78414 22.6553 4.62069C22.5964 4.54319 22.5309 4.4714 22.4598 4.40623C22.382 4.33489 22.2975 4.27149 22.2073 4.21722Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
