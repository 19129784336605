import { Flex, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const PreviewWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.colors.mono1};
  border-left: 1px solid ${props => props.theme.colors.mono3};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

export const PreviewTogglesWrapper = styled.div`
  background-color: ${props => props.theme.colors.mono2};
  border-radius: ${pxToRem(36)};
  margin-bottom: ${props => props.theme.space[5]};
`;
